import IntersectionObserver from 'intersection-observer-polyfill';

type Options = Record<string, IntersectionObserverInit>;

const defaultOptions: Options = {
    '(max-width: 768px)': {
        rootMargin: '0px',
        threshold: 0.1
    },
    '(max-width: 1200px)': {
        rootMargin: '0px',
        threshold: 0.25
    },
    'all': {
        rootMargin: '0px',
        threshold: 0.4
    },
};

export default class InViewportController {

    observer: IntersectionObserver;
    selector: string = '[data-in-viewport]:not(.in-viewport)';
    elements: Set<Element> = new Set<Element>();
    options: [MediaQueryList, IntersectionObserverInit][];
    currentOptions?: IntersectionObserverInit;

    constructor(options: Options = defaultOptions) {
        this.options = Object.entries(options).map(([media, options]) => [window.matchMedia(media), options]);
        for (let [media] of this.options) {
            media.addEventListener?.('change', this.mediaChanged.bind(this));
        }
        this.mediaChanged();
    }

    private update() {
        this.observer?.disconnect();
        this.observer = new IntersectionObserver(this.observe, this.currentOptions);
        for (let element of this.elements) {
            this.observer.observe(element);
        }
    }

    private mediaChanged() {
        const lastOptions = this.currentOptions;
        for (let [media, options] of this.options) {
            if (media.matches) {
                this.currentOptions = options;
                break;
            }
        }
        if (lastOptions !== this.currentOptions) {
            this.update();
        }
    }

    init(snippet: HTMLElement = document.body) {
        const elements: NodeListOf<Element> = snippet.querySelectorAll(this.selector);
        elements.forEach(element => {
            this.observer.observe(element);
            this.elements.add(element);
        });
    }

    observe = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        for (let entry of entries) {
            let {target} = entry;
            target.classList.toggle('in-viewport', entry.isIntersecting);
            if (entry.isIntersecting) {
                if (target.getAttribute('data-in-viewport') !== 'continuous') {
                    observer.unobserve(entry.target);
                    this.elements.delete(entry.target);
                }
            }
        }
    }

}
