import Swiper, {Autoplay, FreeMode, Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, FreeMode]);

interface SwiperElement extends HTMLElement {
    Swiper: Swiper
}

export function initSwipers(snippet: HTMLElement) {
    for (let el of snippet.querySelectorAll<SwiperElement>('[data-swiper]')) {
        if (!el.Swiper) {
            el.Swiper = new Swiper(el, JSON.parse(el.dataset.swiper || '{}'));
        }
    }
}
