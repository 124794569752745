import {Collapse} from "bootstrap";

declare global {
    interface Window {
        dataLayer: any[];
        cookiesSubmitted: (e: SubmitEvent) => void;
    }
}

export const getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
}
export const setCookie = (name, value, exDays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

type Values = string[];
const available = ['analytics', 'marketing'];
const cookieName = 'cookies';

let form;
let bar;

export function initCookies() {
    const settingsRaw = getCookie(cookieName);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'cookie_consent',
        type: settingsRaw
    });
    const settings = (settingsRaw?.split(',').filter(Boolean) || []) as Values;
    form = document.querySelector<HTMLFormElement>('form#cookies-form');
    if (form) {
        initForm(form, settings);
    }
    for (let el of document.querySelectorAll('[data-cookies=allow]')) {
        el.removeEventListener('click', allowAll);
        el.addEventListener('click', allowAll);
    }
    bar = document.querySelector<HTMLDivElement>('#cookies-bar');
    if (bar && settingsRaw === undefined && !form) {
        Collapse.getOrCreateInstance(bar).show();
    }
}

function allowAll() {
    save(available);
    if (bar?.classList.contains('show')) {
        Collapse.getOrCreateInstance(bar).hide();
    }
}

function save(values: Values) {
    setCookie(cookieName, values.join(','), 999);
    initCookies();
}

function initForm(form: HTMLFormElement, values: Values) {
    for (let key of values) {
        if (form.elements[key]) {
            form.elements[key].checked = true;
        }
    }
    form.removeEventListener('submit', submitForm);
    form.addEventListener('submit', submitForm);
}

function submitForm(e: SubmitEvent) {
    e.preventDefault();
    try {
        if ((e.submitter as HTMLButtonElement).name === 'all') {
            save(available);
        } else {
            save([...(new FormData(e.target as HTMLFormElement)).keys()]);
        }
    } catch (e) {
        console.error(e);
    }finally {
        window.location.href = '/';
    }
}

window.cookiesSubmitted = submitForm;
