interface CountdownElement extends HTMLElement {
    Countdown: Countdown
}

export default class Countdown {

    private readonly el: HTMLElement;
    private readonly interval: number;
    private readonly seconds: Element | null;
    private readonly days: Element | null;
    private readonly minutes: Element | null;
    private readonly hours: Element | null;
    private readonly end: number;

    public static initSnippet(snippet: HTMLElement) {
        for (let el of snippet.querySelectorAll<CountdownElement>('[data-countdown]')) {
            if (!el.Countdown) {
                el.Countdown = new Countdown(el, Date.parse(`${el.dataset.countdown}`));
            }
        }
    }

    constructor(el: HTMLElement, end: number) {
        this.el = el;
        this.end = end;
        this.days = el.querySelector('[data-days]');
        this.hours = el.querySelector('[data-hours]');
        this.minutes = el.querySelector('[data-minutes]');
        this.seconds = el.querySelector('[data-seconds]');
        this.interval = window.setInterval(this.update.bind(this), 1000);
        this.update();
    }

    update() {
        const t = this.getTimeRemaining(this.end);
        this.days && (this.days.innerHTML = t.days.toString());
        this.hours && (this.hours.innerHTML = t.hours.toString());
        this.minutes && (this.minutes.innerHTML = t.minutes.toString());
        this.seconds && (this.seconds.innerHTML = t.seconds.toString());
        if (t.total <= 0) {
            clearInterval(this.interval);
        }
    }

    getTimeRemaining(end: number) {
        const total = end - Date.now();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        return {total, days, hours, minutes, seconds};
    }
}


