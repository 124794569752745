import 'bootstrap';
import Countdown from "./Countdown";
import {initSwipers} from "./SwiperElement";
import InViewportController from "./InViewportController";
import {initCookies} from "./cookies";
import {Collapse, Tab} from "bootstrap";

function onLoad(callback) {
    if (document.readyState !== 'loading') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

onLoad(() => {
    initCookies();
    Countdown.initSnippet(document.body);
    initSwipers(document.body);
    const inViewportController = new InViewportController();
    inViewportController.init();
    const navCollapse = document.querySelector('.navbar-collapse ');
    if (navCollapse) {
        const hideNav = () => {
            if (navCollapse.classList.contains('show')) {
                Collapse.getOrCreateInstance(navCollapse, {toggle: false}).hide();
            }
        };
        document.body.addEventListener('click', hideNav);
        window.addEventListener('scroll', hideNav);
    }
    document.addEventListener('show.bs.tab', function (event: Event) {
        if (event.target instanceof HTMLElement) {
            const tab = document.querySelector(event.target.dataset.bsTarget as string)
                ?.querySelector('[data-bs-toggle=tab]');
            if (tab) {
                Tab.getOrCreateInstance(tab).show();
            }
        }
    })
});


